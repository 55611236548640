@use 'functions' as f;

@mixin mq($key, $invert: false) {
	$breakpoint: f.get-breakpoint-width($key);

	@if $breakpoint {
		@if $invert {
			@media not all and (min-width: #{$breakpoint}) {
				@content;
			}
		} @else {
			@media (min-width: #{$breakpoint}) {
				@content;
			}
		}
	}
}

@mixin visually-hidden {
	// stylelint-disable declaration-no-important
	display: block !important;
	position: fixed !important;
	top: 0 !important;
	left: 0 !important;
	visibility: visible !important;
	opacity: 0 !important;
	margin: 0 !important;
	border: 0 !important;
	padding: 0 !important;
	width: 4px !important;
	height: 4px !important;
	overflow: hidden !important;
	pointer-events: none !important;
	// stylelint-enable declaration-no-important
}

@mixin visually-hidden-focusable() {
	&:not(:focus, :focus-within) {
		@include visually-hidden();
	}
}
