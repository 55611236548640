@use '../../abstracts/' as a;

.p-feature {
	position: relative;

	&::before {
		display: block;
		background-image: url('/assets/images/pages/consultation/feature_bg_head.png');
		background-size: 100%;
		background-repeat: no-repeat;
		padding-top: calc(13 / 750 * 100%);
		width: 100%;
		content: '';
	}

	&::after {
		display: block;
		background-image: url('/assets/images/pages/consultation/feature_bg_btm.png');
		background-size: 100%;
		background-repeat: no-repeat;
		padding-top: calc(13 / 750 * 100%);
		width: 100%;
		content: '';
	}
}

.p-feature__container {
	background-color: var(--color-yellow);
	padding: calc(80 / 750 * 100%) calc(68 / 750 * 100%) calc(110 / 750 * 100%);
}

.p-feature__heading {
	color: var(--color-blue);
	font-weight: 700;
	font-size: 3.2rem;
	font-family: var(--font-zen);
	letter-spacing: 0.1em;
	text-align: center;

	@include a.mq(md) {
		font-size: 5.4rem;
	}
}

.p-feature__list {
	margin-top: calc(40 / 614 * 100%);
}

.p-feature__item {
	border-top: 1px dotted #fff;
	padding: calc(32 / 614 * 100%) 0 calc(40 / 614 * 100%);

	&:last-child {
		border-bottom: 1px dotted #fff;
	}
}

.p-feature__item-heading {
	color: var(--color-blue);
	font-weight: 700;
	font-size: 2.1rem;
	line-height: calc(60 / 42);
	font-family: var(--font-zen);
	letter-spacing: 0.025em;
	text-align: center;

	@include a.mq(md) {
		font-size: 3.5rem;
	}
}

.p-feature__body {
	font-feature-settings: 'palt';
	margin-top: calc(24 / 614 * 100%);
	padding: 0 calc(8 / 614 * 100%);
	font-weight: 500;
	font-size: 1.3rem;
	line-height: calc(46 / 26);
	letter-spacing: 0.03em;
	text-align: justify;

	@include a.mq(md) {
		font-size: 2.2rem;
	}
}
