.p-consultation {
  position: relative;
  background-image: url("/assets/images/common/bg_blue.jpg");
  background-position: top center;
  background-size: 100%;
  background-repeat: repeat-y;
}
.p-consultation::before {
  display: block;
  background-image: url("/assets/images/common/bg_bdr.png");
  background-size: 100%;
  background-repeat: repeat-x;
  padding-top: 1.7333333333%;
  width: 100%;
  content: "";
}

.p-feature {
  position: relative;
}
.p-feature::before {
  display: block;
  background-image: url("/assets/images/pages/consultation/feature_bg_head.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 1.7333333333%;
  width: 100%;
  content: "";
}
.p-feature::after {
  display: block;
  background-image: url("/assets/images/pages/consultation/feature_bg_btm.png");
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 1.7333333333%;
  width: 100%;
  content: "";
}

.p-feature__container {
  background-color: var(--color-yellow);
  padding: 10.6666666667% 9.0666666667% 14.6666666667%;
}

.p-feature__heading {
  color: var(--color-blue);
  font-weight: 700;
  font-size: 3.2rem;
  font-family: var(--font-zen);
  letter-spacing: 0.1em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-feature__heading {
    font-size: 5.4rem;
  }
}

.p-feature__list {
  margin-top: 6.5146579805%;
}

.p-feature__item {
  border-top: 1px dotted #fff;
  padding: 5.2117263844% 0 6.5146579805%;
}
.p-feature__item:last-child {
  border-bottom: 1px dotted #fff;
}

.p-feature__item-heading {
  color: var(--color-blue);
  font-weight: 700;
  font-size: 2.1rem;
  line-height: 1.4285714286;
  font-family: var(--font-zen);
  letter-spacing: 0.025em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-feature__item-heading {
    font-size: 3.5rem;
  }
}

.p-feature__body {
  font-feature-settings: "palt";
  margin-top: 3.9087947883%;
  padding: 0 1.3029315961%;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 1.7692307692;
  letter-spacing: 0.03em;
  text-align: justify;
}
@media (min-width: 48em) {
  .p-feature__body {
    font-size: 2.2rem;
  }
}

.p-intro {
  margin-top: 9.8666666667%;
}

.p-intro__container {
  margin: 0 auto;
  border-top: 1px dotted #fff;
  border-bottom: 1px dotted #fff;
  padding: 3.3333333333% 0 6.1333333333%;
  width: 82.6666666667%;
}

.p-intro__heading {
  color: #fff;
  font-weight: 700;
  font-size: 2.1rem;
  letter-spacing: 0.1em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-intro__heading {
    font-size: 3.5rem;
  }
}

.p-intro__body {
  margin-top: 3.2258064516%;
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.6333333333;
  letter-spacing: 0.04em;
}
@media (min-width: 48em) {
  .p-intro__body {
    font-size: 2.5rem;
  }
}

.p-intro__btn {
  margin-top: 4.8387096774%;
  padding: 10px 7px;
  width: 81.6129032258%;
  font-size: 1.6rem;
}
@media (min-width: 48em) {
  .p-intro__btn {
    font-size: 2.7rem;
  }
}

.p-mv__container {
  position: relative;
  padding: 5.3333333333% 5.3333333333% 0;
}

.p-mv__img {
  border-radius: 40px 0 40px 0/40px 0 40px 0;
  overflow: hidden;
}

.p-mv__heading {
  margin-top: 7.1641791045%;
}

.p-question {
  padding-top: 9.6%;
}

.p-question__block {
  position: relative;
  margin: 0 auto;
  padding: 8.1333333333% 0 4%;
  width: 82.6666666667%;
}

.p-question__item {
  position: relative;
  z-index: 1;
  animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__item-bg {
  position: absolute;
  top: 8px;
  left: 5px;
  z-index: -1;
  mix-blend-mode: multiply;
}

.p-question__item-body {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
}

.p-question__item--01 {
  margin-left: 1.6129032258%;
  width: 45.3225806452%;
}

.p-question__item--02 {
  animation-delay: 0.2s;
  margin-top: -8.064516129%;
  margin-left: auto;
  width: 40.9677419355%;
}

.p-question__item--03 {
  animation-delay: 0.1s;
  margin-top: -4.0322580645%;
  margin-left: 6.4516129032%;
  width: 40.9677419355%;
}

.p-question__item--04 {
  animation-delay: 0.3s;
  margin-top: -8.8709677419%;
  margin-right: 4.5161290323%;
  margin-left: auto;
  width: 40.9677419355%;
}

.p-question__person {
  position: absolute;
  z-index: 1;
  animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__person--01 {
  top: 0;
  right: 24.5161290323%;
  width: 17.2580645161%;
}

.p-question__person--02 {
  bottom: 0;
  left: 12.2580645161%;
  width: 25.0806451613%;
}

.p-question__obj {
  position: absolute;
  z-index: 1;
  animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__obj-bg {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: -1;
}

.p-question__obj--01 {
  top: 4.8387096774%;
  right: 3.8709677419%;
  width: 12.5806451613%;
}

.p-question__obj--02 {
  top: 0;
  left: 0;
  animation-delay: 0.2s;
  margin-top: 34.8754448399%;
  margin-left: 43.4163701068%;
  width: 37.0106761566%;
}
.p-question__obj--02 .p-question__obj-bg {
  width: 101.9230769231%;
}

.p-question__obj--03 {
  bottom: 0;
  left: 0;
  animation-delay: 0.4s;
  margin-bottom: -11.811023622%;
  margin-left: -10.2362204724%;
  width: 17.3228346457%;
}

.p-question__obj--04 {
  bottom: 0;
  left: 0;
  margin-bottom: -32.2834645669%;
  margin-left: -15.7480314961%;
  width: 30.7086614173%;
}

.p-question__obj--05 {
  top: 0;
  right: 0;
  animation-delay: 0.1s;
  margin-top: -17.3228346457%;
  margin-right: 2.3622047244%;
  width: 16.9291338583%;
}

.p-question__obj--06 {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  animation-delay: 0.4s;
  margin-left: -3.5483870968%;
  width: 11.935483871%;
}

.p-question__body {
  margin: 3.2258064516% auto 0;
  width: 73.5483870968%;
}

.p-resolution {
  position: relative;
  margin-top: 10.9333333333%;
  padding-bottom: 19.3333333333%;
}

.p-resolution__body {
  margin: 0 auto;
  width: 60.8%;
}

.p-resolution__exclamation {
  position: absolute;
  animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-resolution__exclamation--01 {
  top: 0;
  left: 0;
  margin-top: 5.6%;
  margin-left: 5.0666666667%;
  width: 2.1333333333%;
}

.p-resolution__exclamation--02 {
  top: 0;
  right: 0;
  animation-delay: 0.2s;
  margin-top: 2.4%;
  margin-right: 12.5333333333%;
  width: 9.0666666667%;
}

.p-resolution__exclamation--03 {
  bottom: 0;
  left: 0;
  animation-delay: 0.35s;
  margin-bottom: 11.3333333333%;
  margin-left: 12%;
  width: 3.8666666667%;
}

.p-resolution__exclamation--04 {
  right: 0;
  bottom: 0;
  animation-delay: 0.1s;
  margin-right: 5.3333333333%;
  margin-bottom: 9.7333333333%;
  width: 2%;
}

.p-resolution__person {
  position: absolute;
  animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-resolution__person--01 {
  top: 0;
  left: 0;
  margin-top: 9.8666666667%;
  margin-left: 2.6666666667%;
  width: 23.8%;
}

.p-resolution__person--02 {
  top: 0;
  right: 0;
  margin-top: 23.4666666667%;
  margin-right: 7.2%;
  width: 17.9333333333%;
}

.p-schedule__container {
  position: relative;
  padding: 12% 5.6% 19.2%;
}

.p-schedule__heading {
  color: #fff;
  font-weight: 700;
  font-size: 2.1rem;
  font-family: var(--font-zen);
  letter-spacing: 0.05em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-schedule__heading {
    font-size: 3.5rem;
  }
}

.p-schedule__list {
  margin-top: 8.4084084084%;
}

.p-schedule__item {
  border: 1px solid #231815;
  background-color: #fff;
  width: 100%;
}
.p-schedule__item + .p-schedule__item {
  border-top: none;
}

.p-schedule__item-header {
  display: flex;
  position: relative;
  background-color: var(--color-orange);
  width: 100%;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.4285714286;
  text-align: left;
}
@media (min-width: 48em) {
  .p-schedule__item-header {
    font-size: 2.3rem;
  }
}

.p-schedule__item-date {
  font-feature-settings: "palt";
  flex-shrink: 0;
  border-right: 1px solid #231815;
  padding: 3.6036036036% 1.5015015015% 3.6036036036% 3.3033033033%;
  width: 42.042042042%;
  letter-spacing: 0.04em;
}
.p-schedule__item-place {
  font-feature-settings: "palt";
  align-self: center;
  padding-left: 3.7537537538%;
  width: 100%;
  letter-spacing: 0.04em;
}

.p-schedule__item-icon {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  border: 1px solid #231815;
  border-radius: 9999px;
  background-color: #fff;
  width: 21px;
  height: 21px;
}
@media (min-width: 48em) {
  .p-schedule__item-icon {
    right: 22px;
    width: 36px;
    height: 36px;
  }
}
.p-schedule__item-icon::before, .p-schedule__item-icon::after {
  position: absolute;
  transition: transform 0.3s;
  margin: auto;
  inset: 0;
  border-radius: 5px;
  background-color: #231815;
  width: 13px;
  height: 1.5px;
  content: "";
}
@media (min-width: 48em) {
  .p-schedule__item-icon::before, .p-schedule__item-icon::after {
    width: 22px;
    height: 3px;
  }
}
.p-schedule__item-icon::after {
  transform: rotate(90deg);
}

.p-schedule__item-header[aria-expanded=true] .p-schedule__item-icon::after {
  transform: rotate(0);
}

.p-schedule__item:nth-child(even) .p-schedule__item-header {
  background-color: #f3994f;
}

.p-schedule__detail {
  border-top: 1px solid #231815;
}
.p-schedule__detail dt {
  font-feature-settings: "palt";
  border-bottom: 1px solid #231815;
  background-color: #fce2c4;
  padding: 4px 0;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  text-align: center;
}
@media (min-width: 48em) {
  .p-schedule__detail dt {
    font-size: 2.5rem;
  }
}
.p-schedule__detail dd {
  font-feature-settings: "palt";
  padding: 14px 3.3033033033% 12px;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4285714286;
  letter-spacing: 0.05em;
}
@media (min-width: 48em) {
  .p-schedule__detail dd {
    font-size: 2.3rem;
  }
}
.p-schedule__detail dd + dt {
  border-top: 1px solid #231815;
}