@use '../../abstracts/' as a;

.p-mv {
	//
}

.p-mv__container {
	position: relative;
	padding: calc(40 / 750 * 100%) calc(40 / 750 * 100%) 0;
}

.p-mv__img {
	border-radius: 40px 0 40px 0 / 40px 0 40px 0;
	overflow: hidden;
}

.p-mv__heading {
	margin-top: calc(48 / 670 * 100%);
}
