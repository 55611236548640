@use '../../abstracts/' as a;

.p-consultation {
	position: relative;
	background-image: url('/assets/images/common/bg_blue.jpg');
	background-position: top center;
	background-size: 100%;
	background-repeat: repeat-y;

	&::before {
		display: block;
		background-image: url('/assets/images/common/bg_bdr.png');
		background-size: 100%;
		background-repeat: repeat-x;
		padding-top: calc(13 / 750 * 100%);
		width: 100%;
		content: '';
	}
}
