@use '../../abstracts/' as a;

.p-question {
	padding-top: calc(72 / 750 * 100%);
}

.p-question__block {
	position: relative;
	margin: 0 auto;
	padding: calc(61 / 750 * 100%) 0 calc(30 / 750 * 100%);
	width: calc(620 / 750 * 100%);
}

.p-question__item {
	position: relative;
	z-index: 1;
	animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__item-bg {
	position: absolute;
	top: 8px;
	left: 5px;
	z-index: -1;
	mix-blend-mode: multiply;
}

.p-question__item-body {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
}

.p-question__item--01 {
	margin-left: calc(10 / 620 * 100%);
	width: calc(281 / 620 * 100%);
}

.p-question__item--02 {
	animation-delay: 0.2s;
	margin-top: calc(-50 / 620 * 100%);
	margin-left: auto;
	width: calc(254 / 620 * 100%);
}

.p-question__item--03 {
	animation-delay: 0.1s;
	margin-top: calc(-25 / 620 * 100%);
	margin-left: calc(40 / 620 * 100%);
	width: calc(254 / 620 * 100%);
}

.p-question__item--04 {
	animation-delay: 0.3s;
	margin-top: calc(-55 / 620 * 100%);
	margin-right: calc(28 / 620 * 100%);
	margin-left: auto;
	width: calc(254 / 620 * 100%);
}

.p-question__person {
	position: absolute;
	z-index: 1;
	animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__person--01 {
	top: 0;
	right: calc(152 / 620 * 100%);
	width: calc(107 / 620 * 100%);
}

.p-question__person--02 {
	bottom: 0;
	left: calc(76 / 620 * 100%);
	width: calc(155.5 / 620 * 100%);
}

.p-question__obj {
	position: absolute;
	z-index: 1;
	animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-question__obj-bg {
	position: absolute;
	top: 4px;
	left: 4px;
	z-index: -1;
}

.p-question__obj--01 {
	top: calc(30 / 620 * 100%);
	right: calc(24 / 620 * 100%);
	width: calc(78 / 620 * 100%);
}

.p-question__obj--02 {
	top: 0;
	left: 0;
	animation-delay: 0.2s;
	margin-top: calc(98 / 281 * 100%);
	margin-left: calc(122 / 281 * 100%);
	width: calc(104 / 281 * 100%);

	.p-question__obj-bg {
		width: calc(106 / 104 * 100%);
	}
}

.p-question__obj--03 {
	bottom: 0;
	left: 0;
	animation-delay: 0.4s;
	margin-bottom: calc(-30 / 254 * 100%);
	margin-left: calc(-26 / 254 * 100%);
	width: calc(44 / 254 * 100%);
}

.p-question__obj--04 {
	bottom: 0;
	left: 0;
	margin-bottom: calc(-82 / 254 * 100%);
	margin-left: calc(-40 / 254 * 100%);
	width: calc(78 / 254 * 100%);
}

.p-question__obj--05 {
	top: 0;
	right: 0;
	animation-delay: 0.1s;
	margin-top: calc(-44 / 254 * 100%);
	margin-right: calc(6 / 254 * 100%);
	width: calc(43 / 254 * 100%);
}

.p-question__obj--06 {
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	animation-delay: 0.4s;
	margin-left: calc(-22 / 620 * 100%);
	width: calc(74 / 620 * 100%);
}

.p-question__body {
	margin: calc(20 / 620 * 100%) auto 0;
	width: calc(456 / 620 * 100%);
}
