@use '../../abstracts/' as a;

.p-resolution {
	position: relative;
	margin-top: calc(82 / 750 * 100%);
	padding-bottom: calc(145 / 750 * 100%);
}

.p-resolution__body {
	margin: 0 auto;
	width: calc(456 / 750 * 100%);
}

.p-resolution__exclamation {
	position: absolute;
	animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-resolution__exclamation--01 {
	top: 0;
	left: 0;
	margin-top: calc(42 / 750 * 100%);
	margin-left: calc(38 / 750 * 100%);
	width: calc(16 / 750 * 100%);
}

.p-resolution__exclamation--02 {
	top: 0;
	right: 0;
	animation-delay: 0.2s;
	margin-top: calc(18 / 750 * 100%);
	margin-right: calc(94 / 750 * 100%);
	width: calc(68 / 750 * 100%);
}

.p-resolution__exclamation--03 {
	bottom: 0;
	left: 0;
	animation-delay: 0.35s;
	margin-bottom: calc(85 / 750 * 100%);
	margin-left: calc(90 / 750 * 100%);
	width: calc(29 / 750 * 100%);
}

.p-resolution__exclamation--04 {
	right: 0;
	bottom: 0;
	animation-delay: 0.1s;
	margin-right: calc(40 / 750 * 100%);
	margin-bottom: calc(73 / 750 * 100%);
	width: calc(15 / 750 * 100%);
}

.p-resolution__person {
	position: absolute;
	animation: 3s ease-in-out infinite alternate fuwafuwa;
}

.p-resolution__person--01 {
	top: 0;
	left: 0;
	margin-top: calc(74 / 750 * 100%);
	margin-left: calc(20 / 750 * 100%);
	width: calc(178.5 / 750 * 100%);
}

.p-resolution__person--02 {
	top: 0;
	right: 0;
	margin-top: calc(176 / 750 * 100%);
	margin-right: calc(54 / 750 * 100%);
	width: calc(134.5 / 750 * 100%);
}
