@use '../../abstracts/' as a;

.p-schedule {
	//
}

.p-schedule__container {
	position: relative;
	padding: calc(90 / 750 * 100%) calc(42 / 750 * 100%) calc(144 / 750 * 100%);
}

.p-schedule__heading {
	color: #fff;
	font-weight: 700;
	font-size: 2.1rem;
	font-family: var(--font-zen);
	letter-spacing: 0.05em;
	text-align: center;

	@include a.mq(md) {
		font-size: 3.5rem;
	}
}

.p-schedule__list {
	margin-top: calc(56 / 666 * 100%);
}

.p-schedule__item {
	border: 1px solid #231815;
	background-color: #fff;
	width: 100%;

	& + & {
		border-top: none;
	}
}

.p-schedule__item-header {
	display: flex;
	position: relative;
	background-color: var(--color-orange);
	width: 100%;
	color: #fff;
	font-size: 1.4rem;
	line-height: calc(40 / 28);
	text-align: left;

	@include a.mq(md) {
		font-size: 2.3rem;
	}
}

.p-schedule__item-date {
	font-feature-settings: 'palt';
	flex-shrink: 0;
	border-right: 1px solid #231815;
	padding: calc(24 / 666 * 100%) calc(10 / 666 * 100%) calc(24 / 666 * 100%)
		calc(22 / 666 * 100%);
	width: calc(280 / 666 * 100%);
	letter-spacing: 0.04em;

	@include a.mq(md) {
		//
	}
}

.p-schedule__item-place {
	font-feature-settings: 'palt';
	align-self: center;
	padding-left: calc(25 / 666 * 100%);
	width: 100%;
	letter-spacing: 0.04em;
}

.p-schedule__item-icon {
	position: absolute;
	top: 50%;
	right: 13px;
	transform: translateY(-50%);
	border: 1px solid #231815;
	border-radius: 9999px;
	background-color: #fff;
	width: 21px;
	height: 21px;

	@include a.mq(md) {
		right: 22px;
		width: 36px;
		height: 36px;
	}

	&::before,
	&::after {
		position: absolute;
		transition: transform 0.3s;
		margin: auto;
		inset: 0;
		border-radius: 5px;
		background-color: #231815;
		width: 13px;
		height: 1.5px;
		content: '';

		@include a.mq(md) {
			width: 22px;
			height: 3px;
		}
	}

	&::after {
		transform: rotate(90deg);
	}
}

.p-schedule__item-header[aria-expanded='true'] {
	.p-schedule__item-icon::after {
		transform: rotate(0);
	}
}

.p-schedule__item:nth-child(even) {
	.p-schedule__item-header {
		background-color: #f3994f;
	}
}

.p-schedule__detail {
	border-top: 1px solid #231815;

	dt {
		font-feature-settings: 'palt';
		border-bottom: 1px solid #231815;
		background-color: #fce2c4;
		padding: 4px 0;
		font-weight: 700;
		font-size: 1.5rem;
		letter-spacing: 0.1em;
		text-align: center;

		@include a.mq(md) {
			font-size: 2.5rem;
		}
	}

	dd {
		font-feature-settings: 'palt';
		padding: 14px calc(22 / 666 * 100%) 12px;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: calc(40 / 28);
		letter-spacing: 0.05em;

		@include a.mq(md) {
			font-size: 2.3rem;
		}
	}

	dd + dt {
		border-top: 1px solid #231815;
	}
}
