@use '../../abstracts/' as a;

.p-intro {
	margin-top: calc(74 / 750 * 100%);
}

.p-intro__container {
	margin: 0 auto;
	border-top: 1px dotted #fff;
	border-bottom: 1px dotted #fff;
	padding: calc(25 / 750 * 100%) 0 calc(46 / 750 * 100%);
	width: calc(620 / 750 * 100%);
}

.p-intro__heading {
	color: #fff;
	font-weight: 700;
	font-size: 2.1rem;
	letter-spacing: 0.1em;
	text-align: center;

	@include a.mq(md) {
		font-size: 3.5rem;
	}
}

.p-intro__body {
	margin-top: calc(20 / 620 * 100%);
	color: #fff;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: calc(49 / 30);
	letter-spacing: 0.04em;

	@include a.mq(md) {
		font-size: 2.5rem;
	}
}

.p-intro__btn {
	margin-top: calc(30 / 620 * 100%);
	padding: 10px 7px;
	width: calc(506 / 620 * 100%);
	font-size: 1.6rem;

	@include a.mq(md) {
		font-size: 2.7rem;
	}
}
